<template>

  <div class="votes">    
    <h2 class="ptl">{{ $t('journal_view_voting_title') }}
      <NuxtLink class="ptla" to="/journal/voting">{{ $t('universal_see_more') }}</NuxtLink>
    </h2>
    <div class="vote_list"> 

      <div v-for="(opt,ind) in data" :class="'vit vote_' + opt.vote_id + ' ' + (opt.is_user_vote ? 'voted' : '')"> 
        <div class="nm">
          <NuxtLink class="ttl" :to="opt.link">{{ opt.title }}</NuxtLink>
        </div>
        <div class="vote_btn">
          <div class="cnt">
            {{ opt.cnt_votes }}
          </div>
          <div class="tx" @click="vote(opt)">
            {{ $plurals.get('vote', opt.cnt_votes) }}
          </div>
        </div>
      </div>
    
    </div>
  </div>

</template>

<script>

export default {   
  components: {   
  },
  props: [
    'data'
    //data:{
      //type: Object
    //}
  ],
  data () {
    return {            
    }
  }, 
  created() {    
    // this.query = this.$route.query.q;
  },
  methods: {
    vote(vote){
      this.$api.postJournalVote(vote.vote_id)
      .then((res) => {
         vote.cnt_votes += parseInt(res.cnt);
      })
      .catch(function (error) {

      });
    }
  }
}
</script>

<style scoped>
 
 .vote_list{

}

.vote_list .vit{
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.vbox .vit{
    float: right;
    display: inline-block;
    width: auto;
    margin-right: auto;
    margin-top: 1rem;
}
.vote_list .vit .nm{
    font-weight: 500;
    margin-right: 2rem;
}
.vote_list .vit .vote_btn{
    display: flex;
    margin-left: auto;
    border: 1px var(--un-primary-back-color) solid;
    border-radius: 3px; 
    /* padding: 3px 7px; */
    margin-top: -2px;
}
.vote_list .vit .vote_btn .cnt{
    padding: 1px 7px;
    color: var(--un-primary-back-color);
    border-right: 1px var(--un-primary-back-color) solid;
    min-width: 29px;
    text-align: center;
}
.vote_list .vit .vote_btn .tx{
    padding: 1px 13px;
    color: var(--un-primary-back-color);
    cursor:pointer;
}
.vote_list .vit.voted .vote_btn .tx,
.vote_list .vit .vote_btn .tx:hover{

    color: #ffffff;
    background-color: var(--un-primary-back-color);
}


.votes .ptl{
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-size: 0.8rem;
  font-weight: normal;
}
  
.votes .ptla{
  text-transform: initial;
  color: darkgray;
  text-decoration: underline;
  font-size: 1.0rem;
  margin-left: 7px;
  display: inline-block;
  cursor: pointer;
}

@container pb (max-width: 768px) {
    .vbox .vit{ 
        float: none;
        margin-top: auto;
        margin-left: 0;
        margin-right: auto;
    }
}

</style>
